import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../css/Header.css';

const Header = () => {
  return (
    <div>
       <header>
       <Navbar className="nav" expand="md" bg="dark" variant="dark">
        {/* <Container> */}
          <Navbar.Brand href="/">Parth Patel</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto justify-content-center">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/timeline">Timeline</Nav.Link>
              <Nav.Link href="/projects">Projects</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>

      </header> 
    </div>
  )
}

export default Header