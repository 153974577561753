import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useState, useEffect } from 'react';
import projectData from '../data/ProjectData.json';
import '../css/Projects.css';


const Projects = () => {

  console.log("ppp",projectData);
  projectData.sort((a, b) => a.order - b.order);


  return (
    <div>
      {/* <h1>Hello from parth projects</h1> */}

      <Container fluid className='paraStyle'>
       
      {projectData.map((item,index)=>(
        <Row>
        <Card className="p-3 my-3 mx-auto custom-card" style={{ width: '90%', border: '2px solid aliceblue' }}>
          <Card.Body>
            <Card.Title className='paraStyle'>{item.title}</Card.Title>
            <Card.Text>
              Technologies:{item.technologies}
            </Card.Text>
            <Card.Text>
              {item.description}
            </Card.Text>
          </Card.Body>
        </Card>
        </Row>
      ))}
        
    </Container>

      
    </div>
  )
}

export default Projects