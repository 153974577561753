import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import '../css/Home.css';


const Home = () => {
  return (
    <div>
      <Container fluid className="py-3">
        <Row>
          <Col lg={4} className="text-center">
            <img className="my-2 banner" src={"/media/parth_home_banner.gif"} alt="parth patel"/>
          </Col>
          <Col lg={8} className="py-3">
            <p className='paraStyle'>{text_intro_1}</p>
            <br/>
            <p className='paraStyle'>{text_intro_2}</p>
            <br/>
            <p className='paraStyle'>{text_intro_3}</p>
            <br/>
          </Col>
        </Row>
        <Row>
          <Col className="slider">
            <Carousel>
              <Carousel.Item>
                <h1 class="title">Certification</h1>
                <img className="mx-5" src={"/media/microsoft-certified-azure-fundamentals.png"} alt="parth patel"/>
                <img className="mx-5" src={"/media/ibm-certified-associate-developer-quantum-computation-using-qiskit-v0-2x.png"} alt="parth patel"/>
                <img className="mx-5" src={"/media/qiskit-global-summer-school-2023.png"} alt="parth patel"/>
              </Carousel.Item>
              <Carousel.Item>
                <h1 class="title">Certification</h1>
                <img className="mx-5" src={"/media/aws-partner-accreditation-technical.png"} alt="parth patel"/>
                <img className="mx-5" src={"/media/aws-partner-cloud-economics-accreditation.png"} alt="parth patel"/>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>

  )
}

let text_intro_1="As a computer science engineer, I have a strong passion for the latest technology and enjoy working on development projects. My expertise in computer science and genuine enthusiasm for technology allows me to bring innovative solutions to the table. I am always eager to take on new challenges and look forward to utilizing my skills and knowledge to make a valuable contribution to any organization."
let text_intro_2="Additionally, I possess strong problem-solving skills and have the ability to quickly adapt to new technologies and processes. I am a team player who values collaboration and open communication, always seeking ways to improve and optimize systems and processes. I am committed to staying current with industry trends and advancements, and I constantly seek out new learning opportunities to expand my knowledge and skills. With a passion for technology and a drive to succeed, I am confident that I can make a meaningful impact in any organization."
let text_intro_3="As a detail-oriented individual, I always strive for excellence in my work and take pride in delivering high-quality results. My strong communication skills allow me to effectively communicate with team members, stakeholders, and clients, and I am able to explain complex technical concepts in a clear and concise manner. I am also a creative thinker who is able to bring fresh perspectives and new ideas to the table. Whether I am working on a new project or troubleshooting a complex issue, I am always focused on finding the best possible solution. I am excited to bring my skills and experience to a new opportunity and make a positive impact in a dynamic and fast-paced environment.";
export default Home