import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useState, useEffect } from 'react';
import timelineData from '../data/TimelineData.json';
import '../css/Timeline.css';




const Timeline = () => {

  console.log("ppp",timelineData);
  timelineData.sort((a, b) => b.id - a.id);
  // const [timelineData, setTimelineData] = useState([]);
  // useEffect(() => {
  //   fetch('TimelineData.json')
  //     .then(response => response.json())
  //     .then(data => setTimelineData(data));
  // }, []);

  // let codeBlock=``;

  // for(let i=0;i<timelineData.length;i++){
  //   let markup=`
  //   <Row>
  //   <Card className="p-3 my-3 mx-auto" style={{ width: '90%', border: '2px solid black' }}>
  //     <Card.Body>
  //       <Card.Title>${timelineData[i].title}</Card.Title>
  //       <Card.Text>
  //         ${timelineData[i].description}
  //       </Card.Text>
  //     </Card.Body>
  //   </Card>
  //   </Row>`;
  //   codeBlock=codeBlock+markup;
  // }

  
  // <Row>
  // <Card className="p-3 my-3 mx-auto" style={{ width: '90%', border: '2px solid black' }}>
  //   <Card.Body>
  //     <Card.Title>Primary School</Card.Title>
  //     <Card.Text>
  //       Some quick example text to build on the card title and make up the
  //       bulk of the card's content.
  //     </Card.Text>
  //   </Card.Body>
  // </Card>
  // </Row>


  console.log('datap:', timelineData);
  return (
    <div>
      
    {/* <h1>Hello from parth TimeLine</h1> */}
    
    <Container fluid className='paraStyle'>
       
      {timelineData.map((item,index)=>(
        <Row>
        <Card className="p-3 my-3 mx-auto custom-card" style={{ width: '90%', border: '2px solid aliceblue' }}>
          <Card.Body>
            <Card.Title className='paraStyle'>{item.title}</Card.Title>
            <Card.Text>
              Time: {item.time}
              {(item.years>0) ? (
                <div>
                Years: {item.years}
                </div>
              ) : null}
              {(item.years>0) ? (
                <div>
                </div>
              ) : null}
              Place: {item.place}
            </Card.Text>
            <Card.Text>
              {item.description}
            </Card.Text>
          </Card.Body>
        </Card>
        </Row>
      ))}
        
    </Container>
      </div>
  )
}

export default Timeline