import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import '../css/Footer.css';


const Footer = () => {
  return (
    <div className='foot'>
        <footer className="bg-dark">
            <Container>
                <p className="foot-text">Copyright © 2023 Parth Patel.
                <br/>All rights reserved.</p>
            </Container>
        </footer>

    </div>
  )
}

export default Footer