import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/Header";
import Home from './components/Home';
import Timeline from './components/Timeline';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './css/App.css';

import {
  Routes,Route
} from "react-router-dom";
import Certification from './components/Certification';

function App() {
  return (
    <div className="App" >
      <Header/>
      <div className="background-image-container">
      <Routes>
        <Route path="/timeline" element={<Timeline/>} />
        <Route path="/projects" element={<Projects/>} /> 
        <Route path="/contact" element={<Contact/>} />
        <Route path="/certification" element={<Certification/>} />
        <Route path="/" element={<Home/>} />
      </Routes>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
